import React from 'react';
import './App.css';
import './index.css';
import Footer from './Components/Footer.js'
import { graphql } from 'gatsby';
import SideBar from './Containers/SideBar';
import ArchiveContainer from './Containers/ArchiveContainer';

const  Archive = ({ data, pageContext }) => {
  let issuesList = data.postgres.issuesList
  let articlesList = data.postgres.articlesList
  
  if (typeof(document) !== 'undefined'){
    document.documentElement.style.background = `#000`
    document.documentElement.style.color = `#FFF`
  }

    return (
      <>
        <div className="main">
          <SideBar
            topic = {'Back Issues'}
            lowlightColor={'000'}
            highlightColor={'FFF'}
          />
          <ArchiveContainer
            issues = {issuesList}
            articles = {articlesList}
          />
        </div>
        <Footer/>
      </>
    );
}

export default Archive;
console.log("### ARCHIVE QUERY ###")
export const query = graphql`
query ArchiveQuery {
    postgres {
    issuesList(orderBy: PUB_DATE_DESC) {
      id
      highlightColor
      lowlightColor
      pubDate
      topic
      slug
    }
    articlesList(orderBy: PUB_DATE_DESC) {
      id
      issue
      pubDate
      slug
      title
      authorName
    }
  }
}
`
export function Head (){
  return (
    <>
       <meta charSet="utf-8" />
       {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
       <meta name="viewport" content="width=device-width, initial-scale=1" />
       <meta name="theme-color" content="#000000" />
       <meta name="description"
         content="Monthly magazine for long-form articles and podcasts on videogames"
       />
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
       <link href="https://fonts.googleapis.com/css2?family=Bitter&family=JetBrains+Mono:wght@400&display=swap" rel="stylesheet" />
       
       <title>Bullet Points Monthly</title>
    </>
  )
}